<template>
  <v-container class="py-7">
    <v-form ref="form" lazy-validation>
      <div class="heading mb-7">Изменить пароль</div>

      <v-text-field
        class="mb-4"
        label="Текущий пароль"
        v-model="currentPass"
        outlined
        :rules="[v => !!v]"
        hide-details
        type="password"
      />

      <v-text-field
        class="mb-4"
        label="Новый пароль"
        v-model="newPass"
        outlined
        :rules="[v => !!v]"
        hide-details
        type="password"
      />

      <v-text-field
        class="mb-4"
        label="Повторите новый пароль"
        v-model="newPassConfirm"
        outlined
        :rules="[v => !!v]"
        hide-details
        type="password"
      />
    </v-form>
  </v-container>
</template>

<script>
import actionButtonMixin from "@/mixins/actionButton";

export default {
  mixins: [actionButtonMixin],

  data: () => ({
    currentPass: "",
    newPass: "",
    newPassConfirm: "",
    loading: false
  }),

  computed: {
    actionButton() {
      return {
        active: true,
        action: this.save,
        text: "Сохранить",
        loading: this.loading
      };
    }
  },

  methods: {
    validate() {
      return this.$refs.form.validate();
    },

    async save() {
      if (!this.validate()) return;

      const { commit, dispatch } = this.$store;
      this.loading = true;
      const params = {
        current_password: this.currentPass,
        password: this.newPass,
        cpassword: this.newPassConfirm
      };
      const response = await dispatch("user/changePassword", params);
      this.loading = false;
      let text;
      if (response.code == 1) {
        text = "Установлен новый пароль";
        this.$router.back();
      } else {
        text = response.msg || "Не удалось установить новый пароль";
      }
      commit("snackbar/update", { active: true, text });
    }
  }
};
</script>